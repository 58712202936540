/* ------------------------------------------------------------------------ Imports */

@font-face {
  font-family: 'ArticulatCF-Bold';
  src: url('../fonts/ArticulatCF-Bold.woff');
    
}

@font-face {
  font-family: 'ArticulatCF-Medium';
  src: url('../fonts/ArticulatCF-Medium.woff');
   
}

@font-face {
  font-family: 'ArticulatCF-Normal';
  src: url('../fonts/ArticulatCF-Normal.woff');
    
}
    
@font-face {
  font-family: 'ArticulatCF-Regular';
  src: url('../fonts/ArticulatCF-Regular.woff');
    
}

@font-face {
  font-family: 'ArticulatCF-LightOblique';
  src: url('../fonts/ArticulatCF-LightOblique.otf');
    
}

@font-face {
  font-family: 'ArticulatCF-Light';
  src: url('../fonts/ArticulatCF-Light.woff');
    
}

@font-face {
  font-family: 'Helvetica';
  src: url('../fonts/Helvetica.ttc');
    
}

@font-face {
  font-family: 'MyriadPro-Regular';
  src: url('../fonts/MyriadPro-Regular.otf');
    
}

@font-face {
  font-family: 'Pacifico-Regular';
  src: url('../fonts/Pacifico-Regular.ttf');
    
}

@font-face {
  font-family: 'Quicksand-Bold';
  src: url('../fonts/Quicksand-Bold.ttf');
    
}

@font-face {
  font-family: 'univers-light';
  src: url('../fonts/Universal-Light-Regular.ttf');
    
}

/* ------------------------------------------------------------------------ Vars */
    
  :root {

    /* ------------------------------------ Colors */

      --principal__100: #111820;
      --principal__80: #808080;
      --principal__50: #b1b2aa;
      --principal__30: #bebfb7;
      --principal__10: #f4f4f4;
      
      --branding__100: #ffa050;
      --branding__50: #f7dd6b;

      --packaging__100: #f7dd6b;
      --packaging__50: #ff9f50;

      --audiovisual__100: #ffb1a9;
      --audiovisual__50: #ec5b56;

      --illustration__100: #008073;
      --illustration__50: #c1eaf8;

      --campaigns__100: #008073;
      --campaigns__50: #c1eaf8;

      --editorial__100: #ec5b56;
      --editorial__50: #ffb1a9;

    /* ------------------------------------ Fonts */

      --principalTypo__bold: ArticulatCF-Bold;
      --principalTypo__normal: ArticulatCF-Normal;
      --principalTypo__regular: ArticulatCF-Regular;
      --principalTypo__medium: ArticulatCF-Medium;
      --principalTypo__light: ArticulatCF-Light;
      --principalTypo__light__oblique: ArticulatCF-LightOblique;

    /* ------------------------------------ Fonts by project */

      --proyectTypo__GreenWalk: Helvetica;
      --proyectTypo__Peloodos: Quicksand-Bold;
      --proyectTypo__Fortunatas: univers-light;

    }

/* ------------------------------------------------------------------------ Text styles */

h1 {
  font-size: 32px;
  font-family: var(--principalTypo__light);
  font-weight: 100;
  margin: 0;
  padding: 0;
}

h2 {
  font-size: 40px;
  font-family: var(--principalTypo__regular);
  margin: 0;
  padding: 0;

}

h3 {
  font-size: 24px;
  font-family: var(--principalTypo__light);
  font-weight: 100;
  margin: 0;
  padding: 0;

}

p {
  color: var(--principal__100);
  font-family: var(--principalTypo__regular);

}

.p__big {
  font-size: 17px;
  color: var(--principal__100);
  font-family: var(--principalTypo__light);
  line-height: 1.3;

}

strong {
  font-family: var(--principalTypo__medium);

}

hr {
  border: 1px solid var(--principal__100);

}

@media only screen and (min-width: 860px) and (orientation: landscape) {

  h1 {
    font-size: 4.5vw;

  }
  
  h2 {
    font-size: 160px;

  }

  h3 {
    font-size: 48px;

  }

  .p__big {
    font-size: 32px;
    
  }

}