/* Cursor.css */
* {
  cursor: none !important;
}

.custom_cursor {
	z-index: 10 !important;
  position: fixed;
  width: 24px;
  height: 24px;
  background-color: #1a1919;
  border-radius: 50%;
  pointer-events: none;
  cursor: none; /* Oculta el cursor predeterminado del navegador */
}
