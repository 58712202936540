/* ---------------------------------------------- Build */

#principalMenu__mobile { 
    
    display: block;
    width: 100%; 
    position: fixed;
    bottom: 0;
    z-index: 10;
    transition: ease-in-out .5s;

    & li {
        height: 40px;
        z-index: 10;
        position: relative;
        transition: all ease .5s;
    }

    & a {
        cursor: pointer;
    }

    & header {
        height: 40px;
        width: 100%;
        display: flex;
    }

    & ul {
        width: auto;
        height: 100%;
    }


    & li header a:first-child {
        flex-grow: 1;
        
    } 

    & section {

        height: fit-content;
        padding: 24px;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        align-self: flex-end;

        & .menuClose__mobile {
            height: 40px;
            width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 14vh;
            right: 16px;

            & img {
                width: 22px;
            }
        }

        & header, article {
            width: 100%;
        }
    
        & header {
            order: 2;
            margin-top: 24px;
        }
    
        & article {
            order: 1;
        }
    
        & h3 img {
            height: 14px;
            margin-left: 4px;
        }
        
    }
}

.hide {
    height: 0 !important;
    opacity: 0 !important;
    transition: ease all .7s !important;

}

.hidden {
    opacity: 0 !important;
    position: absolute !important;
    height: 0 !important;
    display: none;

}

.show {
    height: auto !important;
    opacity: 1 !important;
    transition: ease all .7s !important;

}

.grow {
    width: 100% !important;
    z-index: 1 !important;
    position: absolute !important;
    height: 100vh !important;
    bottom: 0 !important;
    display: flex !important;
    padding-bottom: 220px !important;
    opacity: 1 !important;
    
}

/* ---------------------------------------------- Styling */

#principalMenu__mobile {

    & header a:first-child {
        font-family: var(--principalTypo__medium) !important;
        font-size: 14px;
        margin-left: 25px;
        display: flex;
        align-items: center;
    }

    & li:nth-child(1) {
        background-color: var(--branding__50);
        
        & a {
            color: var(--branding__100);
        }

        & h2 {
            color: var(--branding__100);
        }

    }

    & li:nth-child(2) {
        background-color: var(--packaging__50);
        
        & a {
            color: var(--packaging__100);
        }

        & h2 {
            color: var(--packaging__100);
        }
    }

    & li:nth-child(3) {
        background-color: var(--audiovisual__50);
        
        & a {
            color: var(--audiovisual__100);
        }

        & h2 {
            color: var(--audiovisual__100);
        }
    }

    & li:nth-child(4) {
        background-color: var(--editorial__50);
        
        & a{
            color: var(--editorial__100);
        }

        & h2 {
            color: var(--editorial__100);
        }
    }

    & li:nth-child(5) {
        background-color: var(--campaigns__100);
        
        & a {
            color: var(--campaigns__50);
        }

        & h2 {
            color: var(--campaigns__50);
        }
    }

    & li:nth-child(6) {
        background-color: var(--illustration__50);
        
        & a {
            color: var(--illustration__100); 
        }

        & h2 {
            color: var(--illustration__100);
        }
    }

}

@media only screen and (min-width: 860px) and (orientation: landscape) {

    #principalMenu__mobile {

        display: none;

    }

}