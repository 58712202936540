.principalMenu {

    display: none;

}


/* ---------------------------------------------- Queries */


@media only screen and (min-width: 860px) and (orientation: landscape) {

    .principalMenu {

        display: block;
        position: fixed;
        width: 0;
        height: auto;
        right: 0;
        bottom: 0;
        z-index: 10;
        transition: ease all .5s;
        opacity: 0;

        & li {
            height: 40px;
            z-index: 10;
            position: relative;
            transition: all ease .5s;
        }

        & header {
            display: block;
            width: 100%;
            display: flex;
        }
       
        & ul {
            width: auto;
            display: flex;
            height: 100%;
        }

        & li header a:first-child {
            flex-grow: 1;
            
        } 

        & li {
            height: auto;
            width: 50px;
            /* background-color: rgba(255, 125, 0, 0.0) !important; */
            
            & > header {
                height: 100%;
                width: 50px;
                display: flex;
                justify-content: center;
                cursor: pointer;
            }

        }
        
        & header a:first-child {
            margin-left: 0;
            margin-bottom: 60px;
            rotate: -90deg;
            align-items: inherit;
            width: 80px;
            position: absolute;
            bottom:10px;
        }

        & section {

            padding-left: 64px;
            height: 100vh;
            padding-bottom: 48px;
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            align-self: flex-end;

            & div:nth-child(1) {

                position: relative;
                width: 94%;
                height: 130px;

                & span {

                    font-size: 48px;
                    font-family: var(--principalTypo__medium);
                    position: absolute;
                    top: 48px;

                }

                & .menuClose {
                    height: 40px;
                    width: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    top: 60px;
                    right: 0;
        
                    & img {
                        width: 24px;
                    }

                }

            }
    

            & header, article {
                width: 100%;
            }
        
            & header {
                order: 2;
                margin-top: 0;
                align-self: flex-end;
            }
        
            & article {
                align-self: flex-start;
                order: 1;
                position: absolute;
                top: 180px;
            }

            & h2 {
                font-size: 8vw;
            }

            & h3 {
                font-size: 2.5vw;
                font-family: var(--principalTypo__regular);
            }

            & h3 img {
                height: 1.5vw;
                margin-left: 10px;
            }

        }

    }  

    .showMenuDesktop {
        width: 300px; 
        opacity: 1;
    
    }

    .showingMenuDesktop {
        width: auto !important;
    }

    /* ---------------------------------------------- Styling */

    .principalMenu {

        & header a:first-child {
            font-family: var(--principalTypo__medium) !important;
            font-size: 14px;
            display: flex;
            align-items: center;
        }

        & li:nth-child(1) {
            background-color: var(--branding__50);
            
            & a {
                color: var(--branding__100);
            }

            & h2 {
                color: var(--branding__100);
            }

            & section {

                & div:nth-child(1) {

                    border-bottom: 2px solid var(--branding__100);

                    & span {
    
                        color: var(--branding__100);
    
                    }

                }

            }

        }

        & li:nth-child(2) {
            background-color: var(--packaging__50);
            
            & a {
                color: var(--packaging__100);
            }

            & h2 {
                color: var(--packaging__100);
            }

            & section {

                & div:nth-child(1) {

                    border-bottom: 2px solid var(--packaging__100);

                    & span {
    
                        color: var(--packaging__100);
    
                    }

                }

            }
        }

        & li:nth-child(3) {
            background-color: var(--audiovisual__50);
            
            & a {
                color: var(--audiovisual__100);
            }

            & h2 {
                color: var(--audiovisual__100);
            }

            & section {

                & div:nth-child(1) {

                    border-bottom: 2px solid var(--audiovisual__100);

                    & span {
    
                        color: var(--audiovisual__100);
    
                    }

                }

            }
        }

        & li:nth-child(4) {
            background-color: var(--editorial__50);
            
            & a{
                color: var(--editorial__100);
            }

            & h2 {
                color: var(--editorial__100);
            }

            & section {

                & div:nth-child(1) {

                    border-bottom: 2px solid var(--editorial__100);

                    & span {
    
                        color: var(--editorial__100);
    
                    }

                }

            }
        }

        & li:nth-child(5) {
            background-color: var(--campaigns__100);
            
            & a {
                color: var(--campaigns__50);
            }

            & h2 {
                color: var(--campaigns__50);
            }

            & section {

                & div:nth-child(1) {

                    border-bottom: 2px solid var(--campaigns__50);

                    & span {
    
                        color: var(--campaigns__50);
    
                    }

                }

            }
        }

        & li:nth-child(6) {
            background-color: var(--illustration__50);
            
            & a {
                color: var(--illustration__100); 
            }

            & h2 {
                color: var(--illustration__100);
            }

            & section {

                & div:nth-child(1) {

                    border-bottom: 2px solid var(--illustration__100);

                    & span {
    
                        color: var(--illustration__100);
    
                    }

                }

            }
        }

    }

    .hide {
        height: 0 !important;
        opacity: 0 !important;
        transition: ease all .5s !important;
    
    }
    
    .hidden {
        opacity: 0 !important;
        position: absolute !important;
        height: 0 !important;
        display: none;
    
    }
    
    .grow {
        width: calc(100vw - 250px) !important;
        position: absolute !important;
        margin-left: calc(-100vw + 250px) !important;
        padding-bottom: 0px !important;

    }

    .fade__in {
        opacity: 1;
        transition: ease all .5s;
    }

    .fade__out {
        opacity: 0;
        transition: ease all .5s;
    }
    
}